unit Unit2;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TForm2 = class(TForm)
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    procedure WebEdit1Exit(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form2: TForm2;

implementation

{$R *.dfm}

procedure TForm2.WebEdit1Exit(Sender: TObject);
var
  ws : String;

begin
  ws := WebEdit1.Text;
  ShowMessage('Unit2: ' + ws)
end;

procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebEdit1 := TEdit.Create('basic-default-fullname');
  WebEdit2 := TEdit.Create('basic-default-company');

  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 770;
    Left := 0;
    TabOrder := 0;
    Top := 0;
    Width := 1266;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.ElementFont := efCSS;
    WebEdit1.ElementPosition := epRelative;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 25;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 380;
    SetEvent(WebEdit1, Self, 'OnExit', 'WebEdit1Exit');
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Text := 'basic-default-company';
    WebEdit1.Top := 72;
    WebEdit1.Width := 100;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Alignment := taLeftJustify;
    WebEdit2.Color := clWindow;
    WebEdit2.ElementFont := efCSS;
    WebEdit2.ElementPosition := epRelative;
    WebEdit2.Font.Charset := ANSI_CHARSET;
    WebEdit2.Font.Color := clBlack;
    WebEdit2.Font.Height := -11;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Size := 8;
    WebEdit2.Font.Style := [];
    WebEdit2.Height := 25;
    WebEdit2.HideSelection := False;
    WebEdit2.Left := 786;
    WebEdit2.ShowFocus := True;
    WebEdit2.TabOrder := 0;
    WebEdit2.Top := 72;
    WebEdit2.Width := 100;
  finally
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
  end;
end;

end.