unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  TForm1 = class(TForm)
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    wdHost: THTMLDiv;
    procedure WebEdit1Exit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    CurrentForm: TForm;
    CurrentFormName: String;

    [async] procedure LoadForm(Form: String); async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

uses
  Unit2;

procedure TForm1.WebEdit1Exit(Sender: TObject);
var
  ws : String;

begin
  ws := WebEdit1.Text;
  ShowMessage(ws)
end;

procedure TForm1.WebFormCreate(Sender: TObject);
begin
  wdHost.ElementID := 'divHost';
  //
  LoadForm('Menu1');

end;

procedure TForm1.LoadForm(Form: String);

  procedure AfterCreate(AForm: TObject);
  begin
//    LogAction('Load Form: '+AForm.ClassName+' Loaded ('+IntToStr(MillisecondsBetween(Now, ElapsedTime))+'ms)');
  end;

begin

  if Assigned(CurrentForm) then
  begin
    CurrentForm.Close;
    CurrentForm.Free;
//    asm
//      divHost.replaceChildren();
//    end;
  end;

  CurrentFormName := Form;

  if Form = 'Menu1' then
  begin
    CurrentForm := TForm2.CreateNew(wdHost.ElementID, @AfterCreate);
  end
  else
  begin
    CurrentFormName := 'Invalid Form';

    if Form <> 'Clear' then
    begin
//      divHost.HTML.Text := 'ERROR: Form Not Found ('+Form+')';
    end;
  end;

end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  wdHost := THTMLDiv.Create(Self);

  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  wdHost.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 650;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    TabOrder := 0;
    Top := 0;
    Width := 1018;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.ElementFont := efCSS;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 25;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 557;
    SetEvent(WebEdit1, Self, 'OnExit', 'WebEdit1Exit');
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 82;
    WebEdit1.Width := 100;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Alignment := taLeftJustify;
    WebEdit2.Color := clWindow;
    WebEdit2.ElementFont := efCSS;
    WebEdit2.Font.Charset := ANSI_CHARSET;
    WebEdit2.Font.Color := clBlack;
    WebEdit2.Font.Height := -11;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Size := 8;
    WebEdit2.Font.Style := [];
    WebEdit2.Height := 25;
    WebEdit2.HideSelection := False;
    WebEdit2.Left := 557;
    WebEdit2.ShowFocus := True;
    WebEdit2.TabOrder := 0;
    WebEdit2.Top := 122;
    WebEdit2.Width := 100;
    wdHost.SetParentComponent(Self);
    wdHost.Name := 'wdHost';
    wdHost.Height := 160;
    wdHost.Left := 234;
    wdHost.Top := 168;
    wdHost.Width := 240;
  finally
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    wdHost.AfterLoadDFMValues;
  end;
end;

end.
